<template>
  <div class="contemtsPr top-padding">
    <top-home title="缴纳费用"></top-home>
    <div class="outDiv">
      <div class="payFree_title">
        <ul class="first">
          <li class="firstPic">
            <div style="display:flex;justify-content:center;align-items:center;width:50px;height:50px;padding:8px;background-color:#008dff;border-radius:50px;box-sizing:border-box">
              <van-icon
                name="balance-o"
                color="#f1faff"
                size="28"
              />
            </div>
          </li>
          <li class="zd-detail">
            <div>合计：
              <span>{{showText}}</span>
            </div>
          </li>
          <li class="sapnc">
            <span @click="jumpBillQuery">账单查询</span>
          </li>
        </ul>

      </div>
      <div class="bill_detail">
        <van-collapse
          v-for="(item,index) in zdData.zdList"
          :key="index"
          v-model="activeNames[index]"
          :border="false"
        >
          <van-collapse-item
            :title="item.title"
            name="1"
            :border="false"
          >
            <template #value>
              <div class="pay_num">
                ￥
                <span>{{item.zdje}}</span>
              </div>
            </template>
            <div class="bill_content">
              <div>
                <div>
                  <span>编号：</span>
                  <span>{{item.htbh}}</span>
                </div>
                <div>
                  <span>名称：</span>
                  <span>{{item.xmmc}}</span>
                </div>
              </div>
              <div>
                <div>
                  <span>类型：</span>
                  <span>{{item.lx}}</span>
                </div>
                <div>
                  <span>日期：</span>
                  <span>{{item.zdrq}}</span>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div
          class="bill_empty"
          v-if="zdData.zdList.length<=0"
        >
          <!-- <div class="money-title">预存费用</div>
          <div class="money-list">
            <div
              class="item"
              :class="{'active':item.value==moneyValue}"
              v-for="(item,index) in moneyList"
              :key="index"
              @click="setMoneyFun(item.value)"
            >{{item.title}}</div>
          </div> -->
        </div>
      </div>
      <div class="space-between"></div>
      <div class="outDiv-two">
        <van-field
          v-model="showText"
          type="number"
          label="缴费金额"
          placeholder="请输入缴费金额"
          readonly
        />
        <!-- <div>缴费金额</div>
        <input type="text" v-model="showText" />-->
      </div>
      <div class="space-between"></div>
      <div
        class="pay-list-box"
        :class="{'pay-list-empty':payType.wxKg!=='1' && payType.alKg!=='1'}"
      >
        <ul>
          <li v-if="payType.wxKg==='1' && !isShowPayType">
            <div class="wx-pay">
              <div>
                <van-icon name="wechat-pay" />
              </div>
              <div class="pay-title">微信支付</div>
            </div>
            <van-radio-group v-model="radio">
              <van-radio name="1"></van-radio>
            </van-radio-group>
          </li>
          <li v-if="payType.alKg==='1' && !isShowPayType">
            <div class="wx-pay zfb-pay">
              <div>
                <van-icon name="alipay" />
              </div>
              <div class="pay-title">支付宝支付</div>
            </div>
            <van-radio-group v-model="radio">
              <van-radio name="3"></van-radio>
            </van-radio-group>
          </li>
          <li v-if="isShowPayType">
            <div class="wx-pay">
              <div class="jft-pay">
                <img src="../../../../public/img/工商银行-01.png">
              </div>
              <div class="pay-title">聚富通支付</div>
            </div>
            <van-radio-group v-model="radio">
              <van-radio name="4"></van-radio>
            </van-radio-group>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div
      class="withhold-box"
      @click="$router.push('/Agricultural')"
    >
      <div>农行银行代扣</div>
      <van-icon name="arrow" />
    </div>
    <div
      class="withhold-box"
      @click="$router.push('/DisparkBank')"
    >
      <div>开放银行绑定</div>
      <van-icon name="arrow" />
    </div> -->
    <van-button
      class="pay-btn"
      round
      type="info"
      style="width: 70%"
      @click="applyBank"
    >确定</van-button>

    <div class="tishi">
      <p class="p1">温馨提示：</p>
      <p class="p2">
        1.由于第三方支付的原因，可能会出现支付成功销账延迟的情况。
        <br />2.如果你的支付已经扣款成功，请不要重复提交支付。
        <br />3.支付成功后第二天再核对销账情况。
        <br />
      </p>
    </div>
    <van-popup
      closeable
      v-model="isHistory"
      position="right"
      :style="{ width:'100%' }"
    >
      <history-pay-fee
        :historyData="historyData"
        :refreshFun="refreshFun.bind(this)"
      ></history-pay-fee>
    </van-popup>
  </div>
</template>

<script>
import {
  getPayDataApi,
  getWxPayDataApi,
  getOnlyCodeApi,
  surplusPayApi,
  getPayResultApi,
  getHistoryPayDataApi,
  getPayTypeIsOpentApi,
  getWxAppId,
  getComputerTableApi,
  getAllCostApi
} from '@/reuqest/api.js'
import { mapGetters } from 'vuex'
import _AP from '@/util/ap.js'
import wxConfigInit from '@/util/wxjssdk'
import HistoryPayFee from '@/components/CostManager/PayFee/historyPayFee.vue'

export default {
  components: {
    HistoryPayFee,
  },
  data () {
    return {
      payType: {
        alKg: '',
        wxKg: '',
      },
      isHistory: false,
      isShowActivity: false,
      showText: 0,
      radio: '1',
      activeNames: [],
      activityFormData: {},
      zdData: {
        zdList: [],
      },
      historyData: {},
      moneyList: [
        {
          value: 50,
          title: '50元',
        },
        {
          value: 100,
          title: '100元',
        },
        {
          value: 200,
          title: '200元',
        },
      ],
      moneyValue: 50,
      throttle: true,
      timer: null,
      isShowPayType: false
    }
  },
  computed: {
    ...mapGetters(['wxLoginInfo', 'appId', 'userInfo']),
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      let type = this.$route.query.type
      if (type && type == 'single') {
        let data = JSON.parse(this.$route.query.zdData)
        let searchObj = JSON.parse(this.$route.query.searchObj)
        data = {
          ...data,
          htlx: searchObj.htlx,
          zdlx: searchObj.zdlx,
          lx: `${searchObj.htlx_text}-${searchObj.zdlx_text}`
        }
        this.zdData.zdList[0] = data
        this.activeNames.push([])
        this.showText = data.zdje || 0
      } else {
        const loadingToast = this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        })
        getAllCostApi({}).then((res) => {
          this.showText = res.data.totalFee || 0
          if (res.data.dataList && res.data.dataList.length > 0) {
            res.data.dataList.forEach((item) => {
              this.activeNames.push([])
            })
          }
          this.zdData.zdList = res.data.dataList
          this.showText = res.data.totalFee || 0
          loadingToast.clear()
        })
      }
      getPayTypeIsOpentApi().then((res) => {
        this.payType = res.data
      })
      // getWxAppId().then(res => {
      //   if (this.appId == res.data.param_value) {
      //     this.isShowPayType = true
      //   } else {
      //     this.isShowPayType = false
      //   }
      // })
    },
    jumpBillQuery () {
      this.$router.push('/BillQuery')
    },
    setMoneyFun (value) {
      this.moneyValue = value
      this.showText = value
    },
    onBridgeReady (payRes) {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
            appId: payRes.appId, //公众号ID，由商户传入
            timestamp: payRes.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: payRes.nonceStr, //随机串
            package: payRes.package,
            signType: payRes.signType, //微信签名方式：
            paySign: payRes.paySign, //微信签名
          },
          function (res) {
            if (res.err_msg == 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            }
          }
        )
      }
    },
    async applyBank () {
      if (this.throttle) {
        this.throttle = false
        setTimeout(() => {
          this.throttle = true
        }, 1500)
      }
      if (!this.showText || this.showText == 0 || this.zdData.zdList.length == 0) {
        this.$toast('暂无缴费信息~')
        return false
      }

      if (this.radio == '2') {
        //开通农行代扣
        this.$router.push('/BankWithholding')
      } else if (this.radio == '1' || this.radio == '3' || this.radio == '4') {
        if (!/^(([1-9]\d*)|\d)(\.\d{1,2})?$/.test(this.showText)) {
          this.$toast('请输入正确的金额~')
          return false
        }
        if (
          this.zdData.jy - this.zdData.qfje >= 0 &&
          this.showText == 0 &&
          this.zdData.zdList.length > 0
        ) {
          //直接扣除结余
          this.$dialog
            .confirm({
              title: '缴费',
              message: '您的结余足够抵扣欠费金额，是否确认缴费？',
            })
            .then(() => {
              // on confirm
              const loadingToast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
              })
              surplusPayApi({}).then(() => {
                loadingToast.clear()
                this.$toast.success('缴费成功~')
                this.init()
              })
            })
            .catch(() => {
              // on cancel
            })
          return false
        }
        if (this.showText < this.zdData.qfje - this.zdData.jy) {
          this.$toast('缴费金额不能小于欠费金额~')
          return false
        }
        if (this.showText <= 0) {
          this.$toast('缴费金额不能为零~')
          return false
        }
        const loadingToast = this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 0,
        })
        let uuid = await this.getOnlyCodeFun()
        if (this.radio == '1') {
          //微信支付
          this.wxpayFun(uuid)
          setTimeout(() => {
            loadingToast.clear()
          }, 6000)
        } else if (this.radio == '3') {
          //支付宝支付
          this.zfbPayFun(uuid, loadingToast)
        } else if (this.radio == '4') {
          //聚富通支付
          this.jftPayFun(uuid, loadingToast)
        }
      }
    },
    //微信支付
    async wxpayFun (uuid) {
      let payData = this.zdData.zdList.map(item => {
        return {
          zdlx: item.zdlx,
          htlx: item.htlx,
          zdId: item.id,
          title: item.title,
          htbh: item.htbh,
          xmmc: item.xmmc,
          fee: item.zdje,
          zdrq: item.zdrq
        }
      })
      let type = this.$route.query.type
      let payRes = await getWxPayDataApi({
        openId: this.wxLoginInfo.openId,
        payType: 1,
        uuid,
        fee: this.showText,
        subDataList: payData,
        type: type && type == 'single' ? '1' : '2'
      })

      payRes = JSON.parse(payRes.data)
      //方法1
      // this.onBridgeReady(payRes)
      // 方法2
      wxConfigInit(['chooseWXPay'], window.location.href).then((wx, wxRes) => {
        wx.chooseWXPay({
          timestamp: payRes.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: payRes.nonceStr, // 支付签名随机串，不长于 32 位
          package: payRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: payRes.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: payRes.paySign, // 支付签名
          /*  success: async function (res) {
                // 支付成功后的回调函数
                // this.$toast.success('支付成功~')
                // this.init()
                this.verifyPayResultFun(uuid)
              },
              cancel: function (err) {
                //取消支付触发
                this.$toast('您取消了支付~')
              },
              fail: function (res) {
                this.$toast.fail('支付失败~')
                // 支付失败
              }, */
          complete: (res) => {
            if (res.errMsg == 'chooseWXPay:ok') {
              this.verifyPayResultFun(uuid)
            } else if (res.errMsg == 'chooseWXPay:cancel') {
              this.$toast('您取消了支付~')
            } else {
              this.$toast.fail('支付失败~')
            }
          },
        })
      })
    },
    //支付宝支付
    async zfbPayFun (uuid, loadingToast) {
      let payRes = await getWxPayDataApi({
        openId: this.wxLoginInfo.openId,
        fee: this.showText,
        payType: 2,
        uuid,
      })
      let payHtml = payRes.data
      const div = document.createElement('div')
      div.innerHTML = payHtml
      div.className = 'zfb-pay-form-box'
      div.style.display = 'none'
      document.body.appendChild(div)
      let zfbDom = document.querySelector('.zfb-pay-form-box form')
      if (!zfbDom) {
        this.$toast('支付异常，请使用其他方式支付~')
        return false
      }
      let url = zfbDom.getAttribute('action')
      let zfbContent = zfbDom
        .querySelector('input[name="biz_content"]')
        .getAttribute('value')
      url = `${url}&biz_content=${encodeURIComponent(zfbContent)}`
      loadingToast.clear()
      document.querySelector('.zfb-pay-form-box').remove()
      _AP.pay(url)
    },
    //聚富通支付
    async jftPayFun (uuid, loadingToast) {
      let userData = await getComputerTableApi('1447808304196435969', { blade_user_id: this.userInfo.user_id })
      let url = ''
      if (userData.data.records[0].yhlx && userData.data.records[0].yhlx == '2') {//企业用户支付方式
        let payRes = await getWxPayDataApi({
          openId: this.wxLoginInfo.openId,
          fee: this.showText,
          payType: 4,
          uuid,
        })
        let apiData = JSON.parse(payRes.data)
        if (apiData.success && apiData.success == true) {
          url = apiData.redirectParam
        } else {
          this.$toast(apiData['return_msg'])
        }
      } else {
        let payRes = await getWxPayDataApi({
          openId: this.wxLoginInfo.openId,
          fee: this.showText,
          payType: 3,
          uuid,
        })
        let payHtml = payRes.data
        const div = document.createElement('div')
        div.innerHTML = payHtml
        div.className = 'jft-pay-form-box'
        div.style.display = 'none'
        document.body.appendChild(div)
        let jftDom = document.querySelector('.jft-pay-form-box form')
        if (!jftDom) {
          this.$toast('支付异常，请使用其他方式支付~')
          return false
        }
        url = jftDom.getAttribute('action')
        let jftContent = jftDom
          .querySelector('input[name="biz_content"]')
          .getAttribute('value')
        url = `${url}&biz_content=${encodeURIComponent(jftContent)}`
        document.querySelector('.jft-pay-form-box').remove()
      }

      // _AP.pay(url)
      if (url) {
        let a = document.createElement('a')
        a.id = 'jft_jump_pay'
        a.href = url
        a.style.display = 'none'
        document.body.append(a)
        a.click()
        document.getElementById('jft_jump_pay').remove()
      }
      loadingToast.clear()
    },
    async getOnlyCodeFun () {
      return new Promise((resolve) => {
        getOnlyCodeApi().then((res) => {
          resolve(res.data)
        })
      })
    },
    //校验支付结果
    verifyPayResultFun (uuid) {
      const loadingToast = this.$toast.loading({
        message: '处理中...',
        forbidClick: true,
        duration: 0,
      })
      this.timer = setInterval(() => {
        getPayResultApi(uuid).then((res) => {
          if (res.data) {
            loadingToast.clear()
            this.$toast.success('缴费成功~')
            this.init()
            clearInterval(this.timer)
            this.$router.go(-1)
          }
        })
      }, 1500)
    },
    refreshFun () {
      this.isHistory = false
      this.init()
    },
  },
}
</script>
 
<style lang="less" scoped>
.contemtsPr {
  background-color: rgb(245, 245, 245);
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
.outDiv {
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px;
  background: #fff;
  .payFree_title {
    padding: 20px;
    padding-bottom: 0;
    .first {
      padding-bottom: 7px;
      border-bottom: 1px solid rgb(245, 245, 245);
      display: flex;
      justify-content: space-between;
      .firstPic {
        img {
          width: 40px;
          vertical-align: middle;
          padding-right: 5px;
        }
        span {
          font-size: 18px;
        }
      }

      .sapnc {
        display: flex;
        align-items: center;
        border: 1px solid rgb(230, 230, 230);
        font-size: 17px;
        color: rgb(5, 133, 242);
        border-radius: 5%;
        padding: 0 10px;
      }
    }
    .second {
      font-size: 16px;
      dl {
        display: flex;
        justify-content: space-between;
        padding: 0;
        dd {
          margin: 0;
        }
      }
      .second-dls {
        display: flex;
        justify-content: space-around;
        width: 100%;
        dd {
          width: 33%;
          span {
            display: block;
            padding: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:nth-child(2) {
              color: rgb(33, 164, 254);
              font-weight: 700;
              font-size: 19px;
            }
          }
          &:nth-child(3) {
            span:nth-child(2) {
              color: rgb(255, 76, 76);
            }
          }
        }
      }
    }
    .zd-detail {
      text-align: left;
      font-size: 16px;
      div {
        padding: 10px 0;
        span {
          color: rgb(33, 164, 254);
        }
      }
    }
  }
  .outDiv-two {
    padding: 15px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 5px;
    /deep/.van-cell {
      padding-left: 0;
      .van-field__label {
        font-size: 18px;
        width: auto;
        padding-right: 10px;
      }
      .van-field__value {
        font-size: 18px;
      }
    }

    /* div {
      padding-bottom: 10px;
    }
    input {
      width: 95%;
      border: 1px solid rgb(204, 204, 204);
      padding: 10px;
      border-radius: 5px;
    } */
  }
  /deep/.bill_detail {
    .van-collapse {
      .van-cell__title {
        text-align: left !important;
        font-size: 16px;
        min-width: 60%;
      }
      .pay_num {
        span {
          color: rgb(33, 164, 254);
          font-size: 16px;
          font-weight: bold;
        }
      }
      .van-collapse-item__content {
        .bill_content {
          & > div {
            display: flex;
            justify-content: space-between;
            div {
              span:last-child {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  .bill_empty {
    color: #232323;
    .money-title {
      font-size: 18px;
      text-align: left;
      padding-left: 10px;
      margin-bottom: 10px;
    }
    .money-list {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        width: 100px;
        font-size: 16px;
        padding: 6px 0;
        border: 1px solid #f1f1f1;
        &.active {
          color: #fff;
          background-color: #0585f2;
          border: 1px solid #0585f2;
        }
      }
    }
  }
  /deep/.activity {
    .van-cell {
      display: flex;
      align-items: center;
      .van-cell__title {
        text-align: left;
        img {
          width: 30px;
          vertical-align: middle;
        }
      }
      .van-cell__value {
        font-size: 16px;
        color: black;
      }
    }
    .van-dialog {
      text-align: left;
      .van-dialog__header {
        font-size: 20px;
      }
      .van-dialog__content {
        p {
          margin-left: 20px;
        }
      }
    }
  }
}
.space-between {
  background-color: #f5f5f5;
  height: 10px;
}
.pay-list-box {
  margin-bottom: 10px;
  ul {
    padding: 15px;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      .wx-pay {
        display: flex;
        align-items: center;
        height: 40px;
        img {
          width: 30px;
        }
        i {
          font-size: 24px;
          padding-right: 5px;
          color: #2ba245;
        }
        .pay-title {
          margin-top: -2px;
        }
      }
      .zfb-pay {
        i {
          color: #0585f2;
        }
      }
      .jft-pay {
        width: 30px;
        box-sizing: border-box;
        padding: 6px 6px 6px 0;
        img {
          width: 100%;
          vertical-align: middle;
        }
      }
    }
  }
  &.pay-list-empty {
    display: none;
  }
}
.pay-btn {
  font-size: 16px;
}
.tishi {
  padding: 10px;
  .p1 {
    text-align: left;
    font-size: 15px;
  }
  .p2 {
    text-align: left;
    color: rgb(102, 102, 102);
    font-size: 14px;
  }
}
.withhold-box {
  font-size: 16px;
  background-color: #fff;
  text-align: left;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &:nth-last-of-type(2) {
    margin-bottom: 15px;
  }
}
.title-box {
  background-color: #fff;
  padding: 10px 0;
  color: #232323;
  font-size: 14px;
  margin-bottom: 2px;
}
</style>