import wx from 'weixin-js-sdk'
import { getWXConfigInitApi } from '@/reuqest/api.js'
export const wxConfigInit = (apiList = [], url) => {
  return new Promise((resolve, reject) => {
    getWXConfigInitApi({
      url,
    }).then(
      res => {
        if (res.data.appId) {
          wx.config({
            debug: false,
            appId: res.data.appId,
            timestamp: res.data.timestamp,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: apiList,
            openTagList: ['wx-open-launch-weapp']
          })
          wx.ready(res => {
            // 微信SDK准备就绪后执行的回调。
            resolve(wx, res)
          })
          wx.error(function (res) {
            console.log(res)
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          })
        } else {
          reject(res)
        }
      }).catch(err => {
        reject(err)
      })
  })
}
export default wxConfigInit