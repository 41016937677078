<template>
  <div class="history-pay-box">
    <div class="details-top">历史欠费缴费</div>
    <div class="details-content-box">
      <div class="outDiv">
        <div class="payFree_title">
          <ul class="second">
            <li>
              <dl>
                <dd>
                  <span>户名:</span>
                  <span>{{historyData.hm}}</span>
                </dd>
                <dd class="right">
                  <span>户号:</span>
                  <span>{{historyData.hh}}</span>
                </dd>
              </dl>
            </li>
            <li>
              <dl class="second-dls">
                <dd>
                  <span>账单合计</span>
                  <span>{{historyData.zdhj}}</span>
                </dd>
                <dd>
                  <span>欠费余额</span>
                  <span>{{historyData.qfje}}</span>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
        <div class="space-between"></div>
        <div class="bill_detail">
          <van-collapse
            v-for="(item,index) in historyData.zdList"
            :key="index"
            v-model="activeNames[index]"
            :border="false"
          >
            <van-collapse-item :title="item.title" name="1" :border="false">
              <template #value>
                <div class="pay_num">
                  ￥
                  <span>{{item.zdje}}</span>
                </div>
              </template>
              <div class="bill_content">
                <div>
                  <div>
                    <span>起数：</span>
                    <span>{{item.qs}}</span>
                  </div>
                  <div>
                    <span>止数：</span>
                    <span>{{item.zs}}</span>
                  </div>
                </div>
                <div>
                  <div>
                    <span>用量：</span>
                    <span>{{item.jsl}}</span>
                  </div>
                  <div>
                    <span>金额：</span>
                    <span>￥{{item.sf}}</span>
                  </div>
                </div>
                <div>
                  <div v-if="item.fjf">
                    <span>附加费：</span>
                    <span>￥{{item.fjf}}</span>
                  </div>
                  <div v-if="item.jje">
                    <span>加减额：</span>
                    <span>￥{{item.jje}}</span>
                  </div>
                  <div v-if="item.wyj && (!item.fjf || !item.jje)">
                    <span>违约金：</span>
                    <span>￥{{item.wyj}}</span>
                  </div>
                </div>
                <div v-if="item.fjf && item.jje && item.wyj">
                  <div>
                    <span>违约金：</span>
                    <span>￥{{item.wyj}}</span>
                  </div>
                </div>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="space-between"></div>
        <div class="outDiv-two">
          <van-field
            v-model="historyData.qfje"
            readonly
            type="number"
            label="缴费金额"
            placeholder="请输入缴费金额"
          />
        </div>
        <div class="space-between"></div>
        <div class="pay-list-box">
          <ul>
            <li>
              <div class="wx-pay">
                <div>
                  <img src="../../../assets/PayFee/weixinPayPic.png" style />
                </div>
                <div class="pay-title">微信支付</div>
              </div>
              <van-radio-group v-model="radio">
                <van-radio name="1"></van-radio>
              </van-radio-group>
            </li>
          </ul>
        </div>
        <van-button class="pay-btn" round type="info" style="width: 70%" @click="applyBank">确定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getHistoryWxPayDataApi,
  getOnlyCodeApi,
  getPayResultApi,
} from '@/reuqest/api.js'
import { mapGetters } from 'vuex'
import wxConfigInit from '@/util/wxjssdk'
export default {
  props: ['historyData', 'refreshFun'],
  data() {
    return {
      radio: '1',
      activeNames: [],
    }
  },
  computed: {
    ...mapGetters(['wxLoginInfo']),
  },
  methods: {
    init() {
      this.historyData.zdList.forEach((item) => {
        this.activeNames.push([])
      })
    },
    async applyBank() {
      let uuid = await this.getOnlyCodeFun()
      let payRes = await getHistoryWxPayDataApi({
        openId: this.wxLoginInfo.openId,
        fee: this.historyData.qfje,
        payType: 1,
        uuid,
      })
      payRes = JSON.parse(payRes.data)
      setTimeout(() => {
        loadingToast.clear()
      }, 6000)
      wxConfigInit(['chooseWXPay'], window.location.href).then((wx, wxRes) => {
        wx.chooseWXPay({
          timestamp: payRes.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: payRes.nonceStr, // 支付签名随机串，不长于 32 位
          package: payRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: payRes.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: payRes.paySign, // 支付签名
          complete: (res) => {
            if (res.errMsg == 'chooseWXPay:ok') {
              this.verifyPayResultFun(uuid)
            } else if (res.errMsg == 'chooseWXPay:cancel') {
              this.$toast('您取消了支付~')
            } else {
              this.$toast.fail('支付失败~')
            }
          },
        })
      })
    },
    async getOnlyCodeFun() {
      return new Promise((resolve) => {
        getOnlyCodeApi().then((res) => {
          resolve(res.data)
        })
      })
    },
    //校验支付结果
    verifyPayResultFun(uuid) {
      const loadingToast = this.$toast.loading({
        message: '处理中...',
        forbidClick: true,
        duration: 0,
      })
      this.timer = setInterval(() => {
        getPayResultApi(uuid).then((res) => {
          if (res.data) {
            loadingToast.clear()
            this.$toast.success('缴费成功~')
            this.refreshFun()
            clearInterval(this.timer)
          }
        })
      }, 1500)
    },
  },
  created() {
    this.init()
  },
}
</script>
 
<style lang="less" scoped>
.details-top {
  font-size: 16px;
  background-color: #fff;
  height: 30px;
  padding-top: 16px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
}
.details-content-box {
  height: calc(100vh - 47px);
  overflow-y: auto;
}
.outDiv {
  width: 100%;
  box-sizing: border-box;
  padding: 0 4px;
  background: #fff;
  .payFree_title {
    padding: 20px;
    padding-bottom: 0;
    .first {
      padding-bottom: 7px;
      border-bottom: 1px solid rgb(245, 245, 245);
      display: flex;
      justify-content: space-between;
      .firstPic {
        img {
          width: 40px;
          vertical-align: middle;
          padding-right: 5px;
        }
        span {
          font-size: 18px;
        }
      }

      .sapnc {
        display: flex;
        align-items: center;
        border: 1px solid rgb(230, 230, 230);
        font-size: 17px;
        color: rgb(5, 133, 242);
        border-radius: 5%;
        padding: 0 10px;
      }
    }
    .second {
      font-size: 16px;
      dl {
        display: flex;
        justify-content: space-between;
        padding: 0;
        dd {
          margin: 0;
        }
      }
      .second-dls {
        display: flex;
        justify-content: space-around;
        width: 100%;
        dd {
          width: 33%;
          span {
            display: block;
            padding: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:nth-child(2) {
              color: rgb(33, 164, 254);
              font-weight: 700;
              font-size: 19px;
            }
          }
          &:nth-child(3) {
            span:nth-child(2) {
              color: rgb(255, 76, 76);
            }
          }
        }
      }
    }
  }
  .outDiv-two {
    padding: 15px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 5px;
    /deep/.van-cell {
      padding-left: 0;
      .van-field__label {
        font-size: 18px;
        width: auto;
        padding-right: 10px;
      }
      .van-field__value {
        font-size: 18px;
      }
    }

    /* div {
      padding-bottom: 10px;
    }
    input {
      width: 95%;
      border: 1px solid rgb(204, 204, 204);
      padding: 10px;
      border-radius: 5px;
    } */
  }
  /deep/.bill_detail {
    .van-collapse {
      .van-cell__title {
        text-align: left !important;
        font-size: 16px;
      }
      .pay_num {
        span {
          color: rgb(33, 164, 254);
          font-size: 16px;
          font-weight: bold;
        }
      }
      .van-collapse-item__content {
        .bill_content {
          & > div {
            display: flex;
            justify-content: space-between;
            div {
              span:last-child {
                color: black;
              }
            }
          }
        }
      }
    }
  }
  .bill_empty {
    color: #232323;
    .money-title {
      font-size: 18px;
      text-align: left;
      padding-left: 10px;
      margin-bottom: 10px;
    }
    .money-list {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .item {
        width: 100px;
        font-size: 16px;
        padding: 6px 0;
        border: 1px solid #f1f1f1;
        &.active {
          color: #fff;
          background-color: #0585f2;
          border: 1px solid #0585f2;
        }
      }
    }
  }
  /deep/.activity {
    .van-cell {
      display: flex;
      align-items: center;
      .van-cell__title {
        text-align: left;
        img {
          width: 30px;
          vertical-align: middle;
        }
      }
      .van-cell__value {
        font-size: 16px;
        color: black;
      }
    }
    .van-dialog {
      text-align: left;
      .van-dialog__header {
        font-size: 20px;
      }
      .van-dialog__content {
        p {
          margin-left: 20px;
        }
      }
    }
  }
}
.space-between {
  background-color: #f5f5f5;
  height: 10px;
}
.pay-list-box {
  margin-bottom: 10px;
  ul {
    padding: 15px;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      .wx-pay {
        display: flex;
        align-items: center;
        height: 40px;
        img {
          width: 30px;
        }
        .pay-title {
          margin-top: -2px;
        }
      }
    }
  }
}
.pay-btn {
  font-size: 16px;
}
.tishi {
  padding: 10px;
  .p1 {
    text-align: left;
    font-size: 15px;
  }
  .p2 {
    text-align: left;
    color: rgb(102, 102, 102);
    font-size: 14px;
  }
}
.withhold-box {
  font-size: 16px;
  background-color: #fff;
  text-align: left;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  &:nth-last-of-type(2) {
    margin-bottom: 15px;
  }
}
</style>